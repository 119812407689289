import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore/lite'

const firebaseConfig = {
  apiKey: 'AIzaSyAEe-lNT0Vby0Fte2sPmQLB23rO3Czw1eA',
  authDomain: 'test-59c2b.firebaseapp.com',
  projectId: 'test-59c2b',
  storageBucket: 'test-59c2b.appspot.com',
  messagingSenderId: '518327447791',
  appId: '1:518327447791:web:f3af80895b51981a7b6b1a',
}

const app = initializeApp(firebaseConfig)
export const auth = getAuth()
const provider = new GoogleAuthProvider()
export const db = getFirestore(app)
export const storage = getStorage(app)

export const signInWithGoogle = () => signInWithPopup(auth, provider)
